<template>
    <div class="sumando-latas">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Cálculo"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836230/juegos-cramer/tutoriales/nivel1/percepcion/suma_latas_lhwwr2.mp4"
        text="Seleccione las dos latas cuya cantidad sume el número que aparecera en pantalla."></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning">
            <clock-down minuto="1" segundo="30" @setgameruning="setGameRuning()"></clock-down>
            <div class="sl__game-box-container">
                <div class="sl__game-box">
                    <div class="sl__main-number-container">
                        <div class="sl__main-number">
                            {{sumTotal}}
                        </div>
                    </div>
                    <div class="sl__cans-container">
                        <div 
                        v-for="(item,index) in arrOptions"
                        :key="item.pos"
                        :class="['sl__can-box', 'sl__pos'+item.pos]"
                        
                        >
                            <div class="sl__can-box" @click="getKeyAns(item.clave,index)">
                                <lata-svg :clave="item.clave"></lata-svg>
                                <div class="sl__option-number-container">
                                    <div class="sl__option-number">
                                        {{item.num}}
                                    </div>
                                </div>
                            </div>
                            <div v-if="answerSelected&&(index_answer_selected[0]==index||index_answer_selected[1]==index)" class="sl__option-protect">
                                <div :class="['sl__check-answer',checkAnswer]"></div>
                            </div>
                        </div>
                        <div  v-if="capaProtectora" class="sl__capa-protectora"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!gameRuning" >
            <results-game nombrejuego="sumando latas" nivel="1" inteligencia="calculo"></results-game>
        </div>
    </div>
</template>
<script>
import LataSvg from './LataSvg'
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'
export default {
    components:{
        LataSvg,
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame
    },
    data:()=>({
        puntos:0,
        gameRuning:true,
        sumTotal:0,
        arrOptions:[],
        answerSelected:false,
        checkAnswer:'',
        index_answer_selected:[],
        resCont:0,
        capaProtectora:false,
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
        ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
        getKeyAns(res,index){
            this.answerSelected = true
            this.index_answer_selected.push(index)
            this.resCont++;
            if(res&&this.resCont==2){
                this.capaProtectora = true
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
                this.puntos = this.puntos + 80;
                window.setTimeout(()=>{
                    this.gameGenerator()
                },1000)
            }
            else if(res&&this.resCont<2)
            {
                this.checkAnswer = 'sl__correct-answer'
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
            }
            else{
                this.puntos = this.puntos - 10;
                this.checkAnswer = 'sl__wrong-answer'
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
                this.capaProtectora = true
                window.setTimeout(()=>{
                    this.gameGenerator()
                },1000)
            }
        },
        setGameRuning(){
            this.gameRuning = false
            if(this.puntos<0)
            {
                this.changeScoreGame(0)
                this.changeScoreDescription('insuficiente')
            }
            else{
                this.changeScoreGame(this.puntos)
                if(this.puntos<=200){
                this.changeScoreDescription('insuficiente')
                }
                else if(this.puntos<=400){
                    this.changeScoreDescription('escasa')
                }
                else if(this.puntos<=600){
                    this.changeScoreDescription('suficiente')
                }
                else if(this.puntos<=800){
                    this.changeScoreDescription('buena')
                }
                else{
                    this.changeScoreDescription('optima')
                }
            }
        },
        getRandom(min, max) {
                return Math.floor(Math.random() * (max - min+1) ) + min;
            },
        reordenarArray(arr){
                let a = arr.sort(function() { return Math.random() - 0.5 })
            // console.log(a)
                return a;
            },
        comprobarIgualdad(n1,n2,total){
            if(n1+n2==total)
            {
                return true;
            }
            else{
                return false;
            }
        },
        getArrayResRandom(min,max,n1,n2){
            //let total = max-min;
            let res = [];
            res.push(n1);
            res.push(n2);
            while(!(res.length==9))
            {
                let aux = this.getRandom(min,max);
                let a = res.find((e)=>e==aux)
                if(!a){
                    res.push(aux)
                    
                    for(let i=1;i<res.length;i++){
                        if(this.comprobarIgualdad(res[i],res[i+1],n1+n2)){
                            
                            res.pop()
                        }
                    }
                }
            }
            
            return res;
        },
        gameGenerator(){
            this.capaProtectora = false
            this.checkAnswer = ''
            this.answerSelected = false
            this.resCont = 0;
            this.index_answer_selected = []
            this.arrOptions = []
            let num1 = this.getRandom(10,80)
            let num2 = this.getRandom(10,80)

            let opciones = this.getArrayResRandom(1,80,num1,num2);
            this.reordenarArray(opciones)
            this.reordenarArray(opciones)
            this.sumTotal = num1 + num2;
            for(let i=0;i<9;i++)
            {
                if(opciones[i]==num1||opciones[i]==num2){
                    this.arrOptions.push({
                        clave:true,
                        num:opciones[i],
                        pos:i+1
                    })
                }
                else{
                    this.arrOptions.push({
                        clave:false,
                        num:opciones[i],
                        pos:i+1
                    })
                }
            }
            
            //console.log(this.arrOptions)
        }
    },
    mounted(){
        this.changeMenuBarBottom(false)
        this.gameGenerator()
        this.changeTutorialStatus(true)
    }
}
</script>
<style>
.sumando-latas{
    width: 100%;
    min-height: 100vh;
    background-color: #1F0275;
}
.sl__game-box-container{
    width: 100%;
    margin-top:2em;
    display: flex;
    justify-content: center;
}
.sl__game-box{
    width: 90%;
    height: 53vh;
    background-color: #EEEFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.sl__main-number-container{
    width: 5em;
    height: 5em;
    background-color: rgb(207, 189, 189);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #323232 0px 0px 10px;/* color fondo lata => ABDD73 */
}
.sl__main-number{
    font-size: 3em;
}

.sl__cans-container{
    width: 90%;
    min-height: 33vh;
    display: grid;
    grid-template-columns: repeat(6,3.3em);
    grid-template-rows: repeat(3,6em);
    justify-content: center;
    align-items: center;
    justify-items: center;
    position: relative;
}
.sl__can-box{
    width: 3em;
    height: 90%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.sl__option-number-container{
    width: 2.2em;
    height: 2.2em;
    background-color: #d5ecba;
    position: absolute;
    border-radius: 50%;
    top: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sl__option-number{
    font-size:2em;
    font-weight: bold;
}

.sl__option-protect{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color:transparent;
}
.sl__check-answer{
    width: 2em;
    height: 2em;
    position: absolute;
    top: 0px;
    right: -10px;
    background-size: 100% 100%;
}
.sl__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.sl__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.sl__capa-protectora{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: tomato;
    opacity: 0;
}
.sl__pos1{
    grid-column: 3;
    grid-row: 1;
    
}
.sl__pos2{
    grid-column: 4;
    grid-row: 1;
    
}
.sl__pos3{
    grid-column: 3;
    grid-row: 2;
    transform: translateX(-50%);
}
.sl__pos4{
    grid-column: 4;
    grid-row: 2;
    transform: translateX(-50%);
}
.sl__pos5{
    grid-column: 5;
    grid-row: 2;
    transform: translateX(-50%);
}
.sl__pos6{
    grid-column: 2;
    grid-row: 3;
    
}
.sl__pos7{
    grid-column: 3;
    grid-row: 3;
    
}
.sl__pos8{
    grid-column: 4;
    grid-row: 3;
    
}
.sl__pos9{
    grid-column: 5;
    grid-row: 3;
    
}
@media (min-width: 900px) and (orientation: landscape) {
    .sl__game-box{
        width: 60%;
    }
    
}
</style>