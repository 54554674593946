<template>
  <div class="svg-can">
  </div>
</template>
<script>
export default {
  name: "LataSvg"
};
</script>
<style>
.svg-can{
  content: "";
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/juegos/nivel1/calculo/j3/can.svg');
  background-size: 180% 100%;
  background-position-x: 50%;
}
</style>